export default {
  // Common
  'common.tips.deliveryHome':
    'Het is mogelijk om dit product thuis te laten bezorgen.',
  'common.tips.pickUpStore':
    'Het is mogelijk om dit product af te halen in een winkel.',
  'common.tips.attention':
    'Opgelet: Helaas kunnen we een terugname van make-up producten niet aanvaarden omwille van hygiënische redenen.',

  'common.store.noLocationAuth':
    'Je hebt het delen van je huidige locatie geweigerd. Schakel deze toestemming in je browserinstellingen in',

  'common.text.terms': 'Terms',
  'common.text.newMsg':
    'Ik wil graag op de hoogte gehouden worden van het laatste nieuws',
  'common.text.agreement':
    'Ik ga akkoord met de <a href="{link}" target="_blank" class="text-pfm-black-400 underline hover:no-underline">algemene voorwaarden*</a>',
  'common.text.login': 'Inloggen',
  'common.text.register': 'Registreren',
  'common.text.language': 'Taal',

  'common.text.newsletter': 'Nieuwsbrief',
  'common.text.newsletterSubscription':
    'Blijf op de hoogte!',
  'common.text.advice': 'Advies nodig?',
  'common.text.mail': 'Mail ons',
  'common.text.chat': 'Chat met ons',
  'common.text.question': 'Vragen?',
  'common.text.contact': 'Bel ons',
  'common.text.securePayment': 'BETAALMETHODEN',
  'common.text.searchPlaceholder': 'Waar ben je naar op zoek?',
  'common.text.loginRegister': '',
  'common.text.welcome': 'Welkom',
  'common.text.logout': 'Uitloggen',
  'common.text.product': 'product',
  'common.text.products': 'producten',
  'common.text.continueShopping': 'Verder winkelen',
  'common.text.inclVAT': 'Incl. BTW',
  'common.text.previous': 'Vorige',
  'common.text.next': 'Volgende',
  'common.text.nextStep': 'Volgende stap',
  'common.text.promoCode': 'PROMOCODE',
  'common.text.filterChosen': 'Je gekozen filters:',
  'common.text.price': 'Prijs',
  'common.text.sortOn': 'Sorteer op:',
  'common.text.moreInfoShort': 'meer info',
  'common.text.moreInfo': 'Meer informatie',
  'common.text.about': 'Over',
  'common.text.loading': 'Bezig met laden...',
  'common.text.id': 'id',
  'common.text.color': 'kleur',
  'common.text.toOrder': 'Bestellen',
  'common.text.viewMore': 'Bekijk meer',
  'common.text.viewAll': 'Bekijk alle gegevens',
  'common.text.changeData': 'Wijzig gegevens',
  'common.text.unknown': 'Onbekend',
  'common.text.loadMore': 'Laad meer',
  'common.text.click': 'Klik',
  'common.text.here': 'hier',
  'common.text.clickHere': 'klik hier',
  'common.text.share': 'Deel op {name}',
  'common.text.allow': 'toestaan',
  'common.text.refuse': 'weigeren',

  'title.news': 'Nieuws',
  'title.themes': "Thema's",
  'title.highlight': 'Uitgelicht',

  'text.viewAllPosts': 'Bekijk alle berichten',
  'text.noProduct': 'Geen product',
  'text.free': 'Gratis',
  'text.deliveryUnknown': 'Wordt in volgende stap berekend',
  'text.date': 'Datum',
  'text.amount': 'Bedrag',
  'text.voucher.activeVouchers': 'Mijn actieve vouchers',
  'text.voucher.productVouchers': 'Mijn beschikbare productvouchers',
  'text.voucher.paymentVouchers': 'Mijn beschikbare betaalvouchers',
  'text.voucher.add': 'Adressen',
  'text.sort.WeightingFactor:desc': 'Relevantie',
  'text.sort.GrossPrice:asc': 'Prijs: Laag naar hoog',
  'text.sort.GrossPrice:desc': 'Prijs: Hoog naar laag',
  'text.sort.Title:asc': 'Alfabetisch: A tot Z',
  'text.sort.Title:desc': 'Alfabetisch: Z tot A',
  'text.sort.CreationDate:desc': 'Nieuwste',
  'text.readMore': 'Lees verder',
  'text.redirect': 'Omleiden...',
  'text.mailto.subject': 'Bekijk deze pagina die super tof is',
  'text.mailto.body': 'Bekijk nu dit onwijs toffe {name}',
  'text.register.loyaltyCustomer':
    "Ja, ik wil sparen voor kortingen en extra's met erica",
  'text.cookie.introduction':
    'Deze website maakt gebruik van cookies, voor alle informatie omtrent ons cookiebeleid',
  'text.cookie.policy': 'cookie beleid',

  'description.amount': 'bedrag dat op de kaart nog staat',

  // placeholder
  'placeholder.selectOne': 'Selecteer er een alstublieft',

  // button
  'button.createAccount': 'Account aanmaken',
  'button.register': 'Inschrijven',
  'common.button.checkout': 'Naar volgende stap',
  'common.button.pay': 'Betalen',
  'common.button.toCart': 'Winkelwagen',
  'common.button.removeFilter': 'Verwijder alle filters',
  'common.button.viewAllOrder': 'Bekijk alle bestellingen',
  'common.button.addAddress': 'Adres toevoegen',
  'common.button.addToCartLong': 'Actie toevoegen aan winkelwagen',

  // Login
  'login.title': 'Verder met bestellen',
  'login.existing': 'Bestaande klant',
  'login.forgotPassword': 'Je wachtwoord vergeten',
  'login.oldPassword': 'Oud wachtwoord',
  'login.newPassword': 'Nieuw wachtwoord',
  'login.confirmPassword': 'Herhaal nieuw wachtwoord',
  'login.confirmPasswordNotice':
    'Het wachtwoord moet minimaal 8 karakters en minimaal 1 cijfer bevatten',
  'register.newAccount': 'Nieuw bij erica?',
  'register.newAccountSub': 'Registreer eenvoudig een account.',
  'title.order.guest': 'Bestellen zonder account',
  'subTitle.order.guest': 'Ga verder met bestellen zonder te registreren.',
  'button.order.noAccount': 'Bestellen als gast',
  'login.subExisting': 'Log in om makkelijk en snel bestellen.',

  // user information
  'userInfo.email': 'E-mailadres',
  'userInfo.password': 'Wachtwoord',
  'userInfo.confirmPassword': 'Wachtwoord bevestigen',
  'userInfo.gender': 'Aanhef',
  'userInfo.gender.Male': 'Man',
  'userInfo.gender.Female': 'Vrouw',
  'userInfo.gender.title.Male': 'Dhr.',
  'userInfo.gender.title.Female': 'Mevr.',
  'userInfo.firstName': 'Naam',
  'userInfo.middleName': 'Tussenvoegsel',
  'userInfo.lastName': 'Achternaam',
  'userInfo.phoneNumber': 'Telefoonnummer',
  'userInfo.mobile': 'Mobiel nummer',
  'userInfo.address.land': 'Land',
  'userInfo.address.postalCode': 'Postcode',
  'userInfo.address.houseNumber': 'Huisnummer',
  'userInfo.address.houseNumberExtension': 'Toevoeging',
  'userInfo.address.street': 'Straat',
  'userInfo.address.city': 'Woonplaats',

  // Checkout
  'checkout.overview.remarkPlaceholder':
    'Heb je vragen of opmerking? Laat het ons hier weten.',
  'checkout.shoppingBag': 'winkelwagen',
  'checkout.postageCosts': 'Verzendkosten',
  'checkout.delivery': 'Levering',
  'checkout.personalInformation': 'Persoonlijke gegevens',
  'checkout.tips.requireFields':
    'De velden met een * hebben wij nodig om je bestelling goed te kunnen verwerken.',
  'checkout.noAddressFindWarning':
    'Geen adres gevonden op basis van {postalCode} / {houseNumber}',
  'checkout.overview.shoppingBag': 'Overzicht winkelwagen',
  'checkout.overview.checkoutOrder': 'Bestelling afrekenen',
  'checkout.overview.billingAddress': 'FACTUUR ADRES',
  'checkout.overview.pickupAddress': 'Afhaaladres',
  'checkout.overview.deliveryAddress': 'Verzendadres',
  'checkout.overview.changeDeliveryMethod': 'Wijzig leveringsmethode',
  'checkout.paymentMethod': 'BETAALMETHODE',
  'checkout.success.breadcrumb': 'Bedankt - Order {orderNumber}',
  'checkout.success.title': 'Bedankt voor je bestelling.',
  'checkout.success.packageDealWith':
    'Wij gaan meteen aan de slag met je pakketje.',
  'checkout.success.orderConfirm':
    'Binnen enkele uren ontvang je van ons de orderbevestiging op: <strong>{email}</strong> met <strong>ordernummer {orderNumber}</strong>.',
  'checkout.success.orderReceive':
    'Zodra je bestelling klaar staat in onze winkel te <a href="{link}" class="hover:underline"><strong>{store}</strong></a> ontvang je een bericht per e-mail.',
  'checkout.success.orderInAccount':
    'p.s. Je kunt je bestelling vanaf nu ook terug vinden in je account onder <a href="{link}" class="hover:underline"><strong>mijn bestellingen</strong></a>.',
  'checkout.success.collection':
    '<strong>BELANGRIJKE INFO voor de ophaling </strong>van je pakket:',
  'checkout.success.orderHandOver':
    'Van zodra je de mail met afhaalbevestiging van ons ontvangen hebt. Neem je ordernummer alvast bij de hand, zo kunnen we jouw bestelling sneller overhandigen.',
  'checkout.success.antwerpHover': 'erica',
  'checkout.success.time':
    ' Van maandag tem zaterdag: {time} - in de winkel aan de kassa.',
  'checkout.success.wine': 'erica',
  'checkout.success.orderQuestion':
    '<p>Vragen over je bestelling?</p><p>Meer info in "<a href="{link}" class="hover:underline"><strong>Verzenden & Bezorgen</strong></a>."</p><p><span>Of contacteer erica Online<a href="tel:32032324611" class="hover:underline">(<strong>+32 (0)3 232 46 11</strong>)</a>,</span><span>of mail naar<a href="mailto:webshop@erica.nl" class="hover:underline"><strong>webshop@erica.nl</strong></a>, van maandag t.e.m. vrijdag van 9u tot 18u.</span></p><p><strong class="underline"> TIP </strong>: Neem alvast je ordernummer klaar (≠ factuurnummer), zo kunnen wij je sneller verder helpen.</p>',
  'checkout.success.sign':
    '<p>Enjoy your purchase!</p><p>Have a beautiful day!</p><p>erica</p>',
  'checkout.success.enthusiastic': 'Enthousiast over je bestelling?',
  'checkout.success.social': 'Like ons op social media',
  'checkout.success.socialFacebook': 'Facebook',
  'checkout.success.socialInstagram': 'Instagram',
  'checkout.success.socialPinterest': 'Pinterest',
  'checkout.step.facts': 'Gegevens',
  'checkout.step.overview': 'Overzicht',
  'checkout.step.pay': 'Betalen',
  'checkout.step.confirmation': 'Bevestiging',
  'checkout.warning.autoFix':
    'Als u op "{button}" klikt, worden ze ook automatisch verwijderd.',

  // delivery
  'delivery.personal': 'persoonlijke account',
  'delivery.address.manager': '* Adressen zijn te beheren in je',
  'delivery.address.notPossible':
    'Thuisbezorging is niet mogelijk in alle landen.',

  // pickup
  'pickup.personal': 'persoonlijke account',
  'pickup.store.manager': 'Winkels zijn te beheren in je',

  // Cart
  'cart.Total': 'Totaal {quantity} product(en)',
  'cart.viewShoppingBag': 'Bekijk hele winkelwagen',
  'cart.changeShoppingBag': 'Bekijk/wijzig MIJN Winkelwagen',
  'cart.discount': 'Korting',
  'cart.totalPrice': 'Totaalprijs',
  'cart.delivery.options': 'Leveringsmogelijkheden',
  'cart.delivery.options.PickupLocations': 'Ophalen bij een pickuppoint',
  'cart.delivery.options.PickupMyStore': 'Ophalen bij Mijn Erica vestiging',
  'cart.delivery.options.PickupStores': 'Ophalen bij een Erica winkel',
  'cart.delivery.options.PickupOtherStore':
    'Ophalen bij een andere Erica winkel',
  'cart.delivery.options.GuestPickupOtherStore':
    'Ophalen bij een Erica winkel',
  'cart.delivery.options.DeliveryMyAddress': 'Thuisbezorging',
  'cart.delivery.options.DeliveryAlternativeAddress':
    'Thuisbezorging (alternatief adres)',
  'cart.delivery.options.Pickup':
    'Ophalen bij een Erica winkel',
  'cart.delivery.options.CustomerAddress': 'Thuisbezorging',
  'cart.delivery.options.AlternativeAddress':
    'Thuisbezorging (alternatief adres)',
  'cart.delivery.guest.options.Pickup':
    'Ophalen bij een Erica winkel',
  'cart.delivery.guest.options.CustomerAddress': 'Thuisbezorging',
  'cart.delivery.guest.options.AlternativeAddress':
    'Thuisbezorging',
  'cart.delivery.options.GuestDeliveryAlternativeAddress':
    'Thuisbezorging',
  'cart.noProducts': 'Er staan geen producten in je winkelwagen.',
  'cart.addToCartMobile': 'Toegevoegd!',
  'cart.addToCartDesktop': 'Toegevoegd aan je winkelwagen',
  'cart.inShoppingBag': 'In mijn Winkelwagen',

  'cart.warning.overStock':
    'Sommige producten zijn momenteel niet op voorraad. Verlaag de hoeveelheid of verwijder deze producten.',
  'cart.warning.autoFix': 'om ze automatisch te verwijderen.',
  'cart.warning.promotionCodeNotFound': 'Geen geldige kortingscode.',

  // Search Panel
  'searchPanel.brand': 'Merken',
  'searchPanel.suggestions': 'Suggesties',
  'searchPanel.categories': 'Categorieën',
  'searchPanel.searchResult': 'ZOEKRESULTATEN',

  // Brand
  'brand.title': 'Merken',
  'brand.noResult': 'Nog geen merk',

  // error
  'error.message.notFound': '{name} is niet gevonden.',
  'error.message.product': 'Dit product',
  'error.message.oops': 'Oeps!',
  'error.message.lost': '',
  'error.message.notFoundPage':
    'We kunnen de pagina die je zoekt niet vinden.',
  'error.message.findWayBack': 'LAAT ONS JE HELPEN DE WEG TERUG TE VINDEN.',
  'error.message.backHome': 'Ga naar de homepagina',

  // Product
  'product.connections.Size': 'Andere inhoud',
  'product.connections.Interesting': 'Interessant',

  'product.label.limitedlabel': 'limited',
  'product.label.cleanbeautylabel': 'clean beauty',
  'product.label.nieuwlabel': 'new',
  'product.label.bestsellerlabel': 'best seller',

  'product.text.unit': 'X',
  'product.text.ingredients': 'Ingrediënten',
  'product.text.description': 'Productinformatie',
  'product.text.specifications': 'Specificaties',
  'product.text.usageDescription': 'Gebruik',
  'product.text.reviews': 'Reviews',
  'product.text.introduction': 'Omschrijving',
  'product.text.relatedProducts': 'Gerelateerde producten',
  'product.text.checkStoreStock': 'Bekijk de voorraad van alle stores',
  'product.text.recentlyViewed': 'Recent bekeken',
  'product.text.perPage': 'Producten per pagina',
  'product.text.summaryTitle': 'Deze wordt het',

  'product.availability.NotOrderable':
    'Dit product is niet voorradig maar kunnen we voor je bestellen, de levertijd bedraagt 2 tot 4 weken.',
  'product.availability.InStock':
    'Bestel tot 15u en je order wordt dezelfde dag verstuurd.',
  'product.availability.OutOfStock':
    'Dit product is niet voorradig maar kunnen we voor je bestellen, de levertijd bedraagt 2 tot 4 weken.',

  'product.OutOfStock':
    'Dit product is momenteel niet op voorraad. Verlaag het aantal of verwijder dit product',

  'product.specification.stock': 'Voorraad (WAAR?)',
  'product.specification.brand': 'Merk',
  'product.specification.group': 'Groep',
  'product.specification.categories': 'Categorieën',
  'product.specification.scanCode': 'Barcode',

  'product.facet.BrandTitle': 'Merk',
  'product.facet.WebNodeIds': 'Categorie',
  'product.facet.ExternalLine': 'Collectie',
  'product.facet.GrossPrice': 'Prijs',
  'product.facet.ExternalFragFamily': 'Geurfamilie',
  'product.facet.ExternalContent': 'Inhoud',
  'product.facet.ExternalProductSkinType': 'Huidtype',
  'product.facet.ExternalConcern': 'Huidzorg',
  'product.facet.ExternalFormulations': 'Textuur',
  'product.facet.ExternalCoverage': 'Dekking',
  'product.facet.ExternalFinish': 'Afwerking',
  'product.facet.ExternalSunProtection': 'Zonnebescherming',
  'product.facet.ExternalBenefits': 'Mascara Effect',
  'product.facet.ExternalHairConcern': 'Haarbehoefte',

  // Reviews
  'reviews.review': 'review',
  'reviews.reviews': 'reviews',
  'reviews.noReview': 'Nog geen reviews geschreven',
  'reviews.writeFirstReview': 'Schrijf de eerste review',
  'reviews.writeAReview': 'Schrijf een review',
  'reviews.about': 'Reviews over',
  'reviews.title': 'REVIEW SCHRIJVEN',
  'reviews.rating': 'Beoordeling',
  'reviews.ratings': 'Beoordelingen',
  'reviews.experience': 'Je ervaring',
  'reviews.required': 'required',
  'reviews.button.send': 'Review verzenden',
  'reviews.averageRating': 'Gemiddelde beoordeling',
  'reviews.latest': 'Nieuwste reviews',
  'reviews.viewAll': 'Bekijk alle reviews',
  'reviews.rating.star': 'ster',
  'reviews.rating.stars': 'sterren',
  'reviews.rating.5': 'Uitstekend',
  'reviews.rating.4': 'Goed',
  'reviews.rating.3': '',
  'reviews.rating.2': '',
  'reviews.rating.1': '',
  'reviews.sorting.newestFirst': 'Nieuwste eerst',
  'reviews.sorting.oldestFirst': 'Oudste eerst',
  'reviews.sorting.mostStars': 'Meeste sterren',
  'reviews.sorting.fewestStars': 'Minste sterren',

  // Store
  'store.stores': 'winkels',
  'store.branches': 'Vestigingen',
  'store.closingTime': 'Vandaag open tot {closingTime}',
  'store.inStock': 'op voorraad',
  'store.lastStock': 'Laatste',
  'store.noStock': 'Niet',
  'store.text.open': 'Nu open',
  'store.text.at': 'Na',
  'store.text.close': 'Gesloten',
  'store.text.extraOpeningDays': 'Extra openingsdagen',
  'store.week.sunday': 'zondag',
  'store.week.monday': 'maandag',
  'store.week.tuesday': 'dinsdag',
  'store.week.wednesday': 'woensdag',
  'store.week.thursday': 'donderdag',
  'store.week.friday': 'vrijdag',
  'store.week.saturday': 'zaterdag',
  'page.store.title.contact': 'contact- en adresgegevens',
  'page.store.title.openHours': 'openingstijden',
  'link.gotoGoogleStore': 'plan een route',

  'footer.usp.sample':
    '<h3 class="text-md font-light uppercase">Gratis 3 samples</h3><p>Ontvang 3 samples naar<br />keuze bij aankoop vanaf €20.</p>',
  'footer.usp.free':
    '<h3 class="text-md font-light uppercase">Gratis levering</h3><p>Gratis levering in <br />Europa vanaf €80.</p>',
  'footer.usp.beautyAdvice':
    '<h3 class="text-md font-light uppercase">Beauty Advies</h3><p>Wij beantwoorden al jouw <br />vragen:<a href="mailto:webshop@erica.nl">webshop@erica.nl</a>.</p>',
  'footer.usp.club':
    '<h3 class="text-md font-light uppercase">Fabulous erica Club</h3><p>Spaar punten voor meer<br />Beauty voordelen.</p>',

  // Breadcrumbs
  'breadcrumbs.writeReview': 'Schrijf beoordeling',
  'breadcrumbs.infrared': 'Infrared {name}',
  'breadcrumb.account.personal': 'Persoonlijke gegevens wijzigen',
  'breadcrumb.account.selectOtherStore': 'Mijn winkel wijzigen',
  'breadcrumb.account.changeAddress': 'Adresgegevens wijzigen',

  // Account
  'account.menu.accountOverview': 'Account overzicht',
  'account.menu.myAccount': 'Mijn account',
  'account.menu.myDetails': 'Mijn gegevens',
  'account.menu.orderStatus': 'Orderstatus',
  'account.menu.myPurchases': 'Mijn aankopen',
  'account.menu.myWishlist': 'Mijn verlanglijst',
  'account.menu.myLoyaltyPoints': 'Mijn spaarpunten',

  'account.title.accountOverview': 'Accountoverzicht',
  'account.title.myDetails': 'Mijn gegevens',
  'account.title.orderStatus': 'Mijn bestellingen',
  'account.title.myPurchases': 'MIJN AANKOPEN',
  'account.title.myWishlist': 'Mijn verlanglijst',
  'title.myLoyaltyPoints': 'Mijn spaarpunten',
  'title.myPaymentVouchers': 'Mijn betaal-vouchers',
  'title.myProductVouchers': 'Mijn product-vouchers',
  'account.title.newsletter': 'Nieuwsbrief instellingen',
  'account.title.selectOtherStore': 'Kies een andere winkel',
  'account.title.invoiceAddress': 'Factuur adres',
  'account.title.deliveryAddress': 'Wijzig adressen',

  'account.myProfile': 'mijn profiel',
  'account.myProfile.lastOrder': 'Mijn laatste bestelling',
  'account.title.myDetail': 'Mijn gegevens',
  'account.title.personalInfo': 'Persoonlijke gegevens',
  'account.title.address': 'Adresgegevens',

  'account.tips.hasProducts': 'Er zitten {count} producten in je winkelwagen.',
  'account.tips.noProducts': 'Er zitten geen producten in je winkelwagen.',
  'account.tips.points': 'Je hebt {points} punten gespaard bij erica.',
  'account.tips.noOrder': 'U heeft nog geen bestelling bij ons gedaan.',
  'account.tips.noSelectedStore': 'Je hebt geen winkel geselecteerd.',
  'account.tips.receiveNewsletter': 'Je ontvangt de nieuwsbrief.',

  'account.myDetail.personal': 'Persoonlijke gegevens',
  'account.myDetail.credentials': 'Inloggegevens',
  'account.myDetail.email': 'E-mail',
  'account.myDetail.deliveryAddress': 'Standaard bezorgadres',
  'account.myDetail.deliveryAddress.residentialOrBusinessAddress':
    'Woon- of Vestigingsadres',
  'account.myDetail.deliveryAddress.otherAddresses': 'Overige adressen',
  'account.myDetail.deliveryAddress.edit': 'Pas adres aan',
  'account.myDetail.billingAddress': 'Standaard factuuradres',
  'account.myDetail.myShop': 'Mijn winkel',
  'account.myDetail.newsletter': 'Nieuwsbrief instellingen',
  'account.change.personal': 'Wijzig persoonlijke gegevens',
  'account.change.password': 'Wijzig inloggegevens',
  'account.change.deliveryAddress': 'Bezorgadressen beheren',
  'account.change.billingAddress': 'Factuuradres wijzigen',
  'account.change.myShop': 'Mijn winkel wijzigen',
  'account.change.emailSetting': 'Wijzig e-mail instellingen',
  'account.myLoyaltyPoints.description': 'Beschrijving',
  'account.myLoyaltyPoints.value': 'Waarde',
  'account.myLoyaltyPoints.loyaltyNumber': 'Aantal spaarpunten:',
  'account.myLoyaltyPoints.loyaltyValue': 'Waarde spaarpunten:',

  'account.wishlist.emptyWishlist':
    'Er staan geen producten in je verlanglijst.',
  'account.wishlist.clearWishlist': 'Alle producten verwijderen',
  'account.wishlist.addWishlistToCart': 'Alle producten in winkelwagen',
  'account.wishlist.noStock': 'Max aantal bereikt vanwege beperkte voorraad',

  'account.newsletter.label': 'Nieuwsbrief',
  'account.newsletter.notAccept': 'Nee, ik wil geen nieuwsbrief ontvangen',
  'account.newsletter.isOptIn':
    'Ja, ik wil via de e-mail op de hoogte blijven van de laatste nieuwtjes',

  'account.purchase.details': 'Details',
  'account.purchase.reOrder': 'Opnieuw bestellen',
  'account.purchase.deliveryAddress': 'Verzendadres:',
  'account.purchase.pickupStore': 'Ophalen bij:',
  'account.purchase.billingAddress': 'FACTUUR ADRES:',
  'account.purchase.total': 'Totaal',
  'account.purchase.status': 'Status:',
  'text.orderNumber': 'Ordernummer:',
  'account.purchase.delivery': 'Levering:',
  'account.purchase.paymentMethod': 'Betaalmethode:',

  'account.transaction.buyFrom': 'Gekocht bij',
  'account.transaction.noTransactions':
    'Je hebt nog niets gekocht in één van onze winkels.',

  'account.save': 'Opslaan',
  'account.backToOverview': 'Terug naar overzicht',

  'account.changePasswordSuccess': 'Wachtwoord succesvol gewijzigd!',
  'account.changePasswordFailed': 'Veranderen van wachtwoord mislukt!',
  'account.saveSuccessfully': 'Succesvol opgeslagen',
  'account.saveFailed': 'Opslaan mislukt',
  'text.noAvailableVouchers': 'Er zijn geen vouchers beschikbaar',

  // resetPassword
  'title.forgotPassword': 'Wachtwoord vergeten?',
  'title.forgotPassword.success': 'JE WACHTWOORD VERGETEN',
  'text.nav.forgotPassword': 'Nieuw wachtwoord opvragen',
  'description.forgotPassword':
    'Vul hieronder je e-mailadres in en je ontvangt binnen enkele minuten een link waarmee je een nieuw wachtwoord kunt instellen.',
  'button.forgotPassword': 'Reset wachtwoord',
  'message.forgotPassword.sendSuccess':
    'Er is een e-mail verstuurd naar {email} met hierin een link waarmee je een nieuw wachtwoord kunt instellen',
}
